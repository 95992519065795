<template>
<div>
	<div class="card-body pt-0">
		<div class="loading" v-if="isLoading">
			<div class="d-flex justify-content-center">
				<div class="spinner-grow text-primary" role="status" id="spinner" style="width: 3rem; height: 3rem;" >
					<span class="sr-only">{{ __('setup.step.3.loading') }}</span>
				</div>
			</div>
			<div class="text-center pt-3">
				<!-- <p>{{ __('setup.step.3.accountsLoaded') }} &nbsp;<span>{{ accounts }}</span></p>
				<p class="mb-0">{{ hasStatus }}</p> -->
			</div>
			<button class="btn btn-dark w-25" role="button" @click="reload()"><i class="fas fa-sync"></i> {{ __('setup.step.3.reload') }}</button>
		</div>
		<div class="results" v-if="!isLoading">
			<hr class="mt-0">
			<div>
				<p>{{ __('setup.step.3.manager') }}</p>
				<ul class="managerAcc">
					<li v-for="root in roots" :key="root.rootNo">{{ root.name }}</li>
				</ul>
				<p>{{ __('setup.step.3.accountsLoaded') }}</p>
			</div>
			<div class="input-group mb-3">
				<v-select :options="paginated" label="label" :clearable="false" :placeholder="__('setup.step.3.searchPlaceholder')" v-model="selectedOption" class="w-100" :filterable="false" @search="(query) => (search = query)" @open="onOpen" @close="onClose">

					<template #list-footer>
    				  <li v-show="hasNextPage" ref="load" class="loader">
    				    Loading more options...
    				  </li>
    				</template>
				</v-select>
			</div>
			<p class="lead" v-if="Object.keys(this.selectedOption).length > 0" v-html="__('setup.step.3.selectedAccountNo', {'accountNo': selectedOption.accountNumber, 'accountName': selectedOption.name})"></p>
		</div>
	</div>
	<div class="card-footer border-0 bg-transparent" v-if="!isLoading">
		<div class="btn-group btn-block">
			<button class="btn btn-dark w-25" role="button" @click="reload()"><i class="fas fa-sync"></i> {{ __('setup.step.3.reload') }}</button>
			<button class="btn btn-primary btn-lg btn-block" @click="nextStep()" :disabled="isButtonDisabled">{{ __('setup.step.3.button') }} <i class="fas fa-angle-right"></i></button>
		</div>
	</div>
</div>
</template>

<script>
import vSelect from 'vue-select'
export default {
	name: 'ads-accounts-component',
	components: {
		vSelect
	},
	props: {
		token: {
			type: String,
			default: null,
			required: true
		},
		user_id: {
			type: String,
			default: null,
			required: true
		},
		csrf_token: {
			type: String,
		},
	},
	data () {
		return {
			wasStarted: false,
			isLoading: true,
			selectedOption: [],
			roots: [],
			options: [],
			limit: 20,
			search: '',
			observer: null,
		}
	},
	mounted() {
		this.startFetchingAdsAccount(),
		this.observer = new IntersectionObserver(this.infiniteScroll)
	},
	methods: {
		reload() {
			this.isLoading = true;
			this.selectedOption = [];
			this.fetchAdsAccount();
		},
		nextStep() {
			axios.post('/setup/step/3',{
				token: this.token,
				account: JSON.stringify(this.selectedOption),
			}).then((result) => {
				if (result.data === 'ok') {
					window.location.replace(APP_URL + '/setup/step/4');
				}
				result.data
				console.log(result);
			})
		},
		startFetchingAdsAccount() {
			if (!this.wasStarted) {
				axios.post('/setup/startfetching',{
					token: this.token,
				}).then((result) => {
					this.wasStarted = true;
					this.fetchAdsAccount();
				})
			}
		},
		fetchAdsAccount() {
			axios.post('/setup/fetchaccounts/' + this.user_id,{
				token: this.token,
			}).then((result) => {
				if (result.data.finished == 1) {
					axios.post('/setup/step/3/account-list')
						.then((result) => {

							axios.get(result.data.roots).then(response => {
								this.roots = response.data;
							})

							axios.get(result.data.accounts).then(response => {
								this.options = response.data;
							})

							this.isLoading = false;
						}).catch((err) => {
							console.log(err);
						});

				} else {
					setTimeout(this.fetchAdsAccount, 2500);
				}
			}).catch((err) => {
				console.log(err);
			})
		},
		async onOpen() {
    	  if (this.hasNextPage) {
    	    await this.$nextTick()
    	    this.observer.observe(this.$refs.load)
    	  }
    	},
    	onClose() {
    	  this.observer.disconnect()
    	},
    	async infiniteScroll([{ isIntersecting, target }]) {
    	  if (isIntersecting) {
    	    const ul = target.offsetParent
    	    const scrollTop = target.offsetParent.scrollTop
    	    this.limit += 10
    	    await this.$nextTick()
    	    ul.scrollTop = scrollTop
    	  }
    	},
	},
	computed: {
		isButtonDisabled() {
			return Object.keys(this.selectedOption).length > 0 ? false : true;
		},
		filtered() {
    	  return this.options.filter((country) => country.label.toLowerCase().includes(this.search.toLowerCase()))
    	},
    	paginated() {
    	  return this.filtered.slice(0, this.limit)
    	},
    	hasNextPage() {
    	  return this.paginated.length < this.filtered.length
    	},

	}
}
</script>