window._ = require('lodash');

try {
	window.$ = window.jQuery = require('jquery');
	require('bootstrap/js/src/dropdown');
	require('bootstrap/js/src/button');
} catch (e) {}

window.axios = require('axios');
window.Vue = require('vue');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))
Vue.component('ads-accounts', require('./components/AdsAccountsComponent.vue').default);
Vue.mixin(require('./trans'))
const app = new Vue({
	el: '#app',
});

$('#register .form-check-input').change(function() {
	console.log('as');
	$('#submitter').prop('disabled', $('.form-check-input:checked').length === 0)
})